import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useMenu from 'useMenu';
import {useTranslation} from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserInfo from './UserInfo';
import {tempRealmMapping} from 'Keycloak';
import {getConfig} from '../../config';

export default function UserMenu({actionLogout}) {
  const [t] = useTranslation();
  const {anchorEl, handleOpen, handleClose} = useMenu();
  const open = Boolean(anchorEl);

  const openLink = () => {
    const realm = tempRealmMapping.find(item => item.domain === window.location.hostname)?.realm ?? getConfig("KEYCLOAK_REALM")
    window.open(`https://uranus.vismaconsulting.fi/auth/realms/${realm}/account/password`, '_blank')
  }
  return (
    <>
      <Button
        aria-label={t('menu.title')}
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <AccountCircleOutlinedIcon fontSize="large"/><ExpandMoreIcon/>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem button={false}>
          <UserInfo/>
        </MenuItem>
        <MenuItem onClick={openLink}>
          {t('user.changePassword')}
        </MenuItem>
        <MenuItem
          onClick={actionLogout}
        >
          <ListItemIcon>
            <ExitToAppIcon/>
          </ListItemIcon>
          {t('user.logout')}
        </MenuItem>
      </Menu>
    </>
  );
}
