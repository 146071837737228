import {Suspense} from 'react';
import MiniVariantDrawer from 'MiniVariantDrawer';
import Spinner from 'App/Spinner';
import AppBar from './AppBar';
import {useTranslation} from 'react-i18next';
import NavItems from './AppBar/Navigation/NavItems';
import TitleBlock from './TitleBlock';
import SelectRoleDialog from './SelectRoleDialog';
import Fallback from './Fallback';
import {Authenticated} from '@postinumero/authorization';
import NoRoles from './Pages/NoRoles';
import {useKeycloak} from '@react-keycloak/web';
import moment from 'moment';
import './print-styles.css';
import {getConfig} from '../config';


export default function Page({title, children, printTitle = false}) {
  const [t] = useTranslation();
  const {keycloak} = useKeycloak();
  moment.updateLocale('en', {
    week: {dow: 1}
  })
  const roleInfo = keycloak?.tokenParsed?.resource_access[getConfig("KEYCLOAK_CLIENT_ID")]?.roles ?? [];
  return (
    <MiniVariantDrawer drawerOpenAriaLabel={t('drawer.open')}>
      <MiniVariantDrawer.AppBar>
        <AppBar/>
      </MiniVariantDrawer.AppBar>
      <MiniVariantDrawer.Drawer>
        <NavItems/>
      </MiniVariantDrawer.Drawer>
      <MiniVariantDrawer.Content>
        <Suspense fallback={<Spinner/>}>
          <Authenticated fallback={<Fallback/>}>
            {(!roleInfo || roleInfo?.length === 0) && keycloak.authenticated ? <NoRoles/> :
              <>
                <SelectRoleDialog/>
                <TitleBlock title={title} printTitle={printTitle}/>
                {children}
              </>
            }

          </Authenticated>
        </Suspense>
      </MiniVariantDrawer.Content>
    </MiniVariantDrawer>
  );
}
