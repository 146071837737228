
let config = {
  KEYCLOAK_URL: process.env.KEYCLOAK_URL,
  KEYCLOAK_REALM: process.env.KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID: process.env.KEYCLOAK_CLIENT_ID,
  KEYCLOAK_LOGIN_OPTIONS: {},
};

export function initConfig() {
  const overrides = window.ENV;
  if (overrides != null) {
    config = {...config, ...overrides};
  }
}

export function getConfig(key) {
  const value = config[key];
  if (value == null) {
    console.warn("missing config value:", key);
  }
  return config[key];
}

